





















import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/new/ConditionWrapperMixin";
import { ConditionSetupView } from '../../../logic/mixins/condition-setup-view.mixin'

import { Component, Mixins } from 'vue-property-decorator'

@Component({})
export default class MessageTextConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
